import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const Redirector = () => {
    const location = useLocation();

    useEffect(() => {
        const getQueryParam = (param) => {
            const params = new URLSearchParams(location.search);
            return params.get(param);
        };
        
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;
        
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            // iOS device detected
            window.location.href = 'https://apps.apple.com/gb/app/ticker/id1445401395';
        } else if (/android/i.test(userAgent)) {
            // Android device detected
            window.location.href = 'https://play.google.com/store/apps/details?id=uk.co.ticker.android';
        } else {
            // Fallback: 
            window.location.href = 'https://www.ticker.co.uk/app/';
        }
    }, [location]);

    return <p>Redirecting...</p>;
};

export default Redirector;
