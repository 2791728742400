import React, { useEffect } from 'react';
import {
  Switch,
  Route,
  useLocation
} from 'react-router-dom';

import './css/style.scss';
import './css/intro.css';

import AOS from 'aos';
import { focusHandling } from 'cruip-js-toolkit';

import Homepage from './pages/Homepage';
import Platform from "./pages/Platform";
import Features from './pages/Features';
import About from './pages/About';
import PrivacyPolicy from "./pages/PrivacyPolicy";
import PageNotFound from './pages/PageNotFound';
import RouteChangeTracker from "./utils/RouteChangeTracker";
import TagManager from "react-gtm-module";
import PrivacyPolicyAndroid from "./pages/PrivacyPolicyAndroid";
import MsaRedirect from "./pages/MSARedirect";

function App() {

  const location = useLocation();

  const tagManagerArgs = {
    gtmId: 'G-1D01SBFDWQ'
  }

  TagManager.initialize(tagManagerArgs)

  useEffect(() => {
    AOS.init({
      once: true,
      duration: 600,
      easing: 'ease-out-sine',
    });
  });

  useEffect(() => {
    document.querySelector('html').style.scrollBehavior = 'auto'
    window.scroll({ top: 0 })
    document.querySelector('html').style.scrollBehavior = ''
    focusHandling('outline');
  }, [location.pathname]); // triggered on route change

  return (
    <>
      <Switch>

        <Route exact path="/">
          <Homepage />
        </Route>
        <Route path="/telematics">
          <Features />
        </Route>
        <Route path="/platform-solutions">
          <Platform />
        </Route>
        <Route path="/about">
          <About />
        </Route>
        <Route path="/Blog">
          <Homepage />
        </Route>
        <Route path="/PrivacyPolicy">
          <PrivacyPolicy />
        </Route>
        <Route path="/privacypolicyandroid">
          <PrivacyPolicyAndroid />
        </Route>
        <Route path='/linkedin' component={() => {
          window.location.href = 'https://www.linkedin.com/company/agm-technologies-ltd';
          return null;
        }}/>
        <Route path='/linkedinIC6' component={() => {
          window.location.href = 'https://www.linkedin.com/pulse/driving-green-how-telematics-including-ic6-aid-reducing-michael-grnre/?trackingId=GiWVTAYhlLFK0bP9pyMEsA%3D%3D';
          return null;
        }}/>
        <Route path='/itcVegas' component={() => {
          window.location.href = 'https://www.linkedin.com/pulse/future-telematics-ai-big-data-advanced-features-ic6-ic7-harry-moore-rmbre/?trackingId=fE7T8CndRLvlcltS25N9MA%3D%3D';
          return null;
        }}/>
        <Route path='/diaAMS' component={() => {
          window.location.href = 'https://www.itcdiaeurope.com/featured-insurtechs/agm-technologies-tackling-rising-car-insurance-premiums-with-telematics/?';
          return null;
        }}/>
        <Route path='/tourLife' component={() => {
          window.location.href = 'https://www.itcdiaeurope.com/dia-tv/video/show-tell-agm-technologies-itc-dia-europe-munich/';
          return null;
        }}/>
        <Route path='/marathon' component={() => {
          window.location.href = 'https://www.linkedin.com/pulse/5-compelling-reasons-why-your-business-needs-ic7-telematics-moore/?trackingId=aZ8wKZM259mSDq6f%2FsA8iQ%3D%3D';
          return null;
        }}/>
        <Route path='/sti' component={() => {
          window.location.href = 'https://www.itcdiaeurope.com/featured-insurtechs/agm-technologies-the-ic7-perfecting-crash-detection/?utm_source=diasocial&utm_medium=linkedin&utm_campaign=barcelona23&utm_term=agm';
          return null;
        }}/>
        <Route path="/MSAredirect" component={MsaRedirect} />
        <Route path="*">
          <PageNotFound />
        </Route>
        <div>
          <RouteChangeTracker />
        </div>
      </Switch>
    </>
  );
}

export default App;
